import getFilterValueDisplay from './view-helpers/getFilterValueDisplay';
import { Button } from 'react-bootstrap';
import Modal from '@material-ui/core/Modal';
import { ModalContext } from '../components/ResultModal';
import { FaCalendarAlt, FaFileDownload, FaTimes, FaRegCopy } from 'react-icons/fa'
import { BiGlasses } from 'react-icons/bi'
import { GoInfo } from 'react-icons/go'
import { MdLanguage, MdInfoOutline } from 'react-icons/md'
import { CgInternal } from 'react-icons/cg'
import { getConfig } from '../config/config-helper';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import JSZip from 'jszip'
import JSZipUtils from './jszip-utils'
import { saveAs } from 'save-as'
import styles from '../assets/css/SearchResult.Module.css'
import GetCompoundDoc from './GetCompoundDoc';
import { width } from '@mui/system';
import copy from "copy-to-clipboard";
import { trackDocData } from '../utils/utils';
// import styleModal from '../assets/css/Modal.module.css';
// import Modal from 'react-modal';

//import ReactGA from 'react-ga'
var ElasticAppSearch = require("@elastic/app-search-javascript");

const { public_url, auth_url, search_key, engineName, auth_download_url, download_url/*, TRACKING_ID */, mimeTypes, supportedMimeTypes } = getConfig();
//ReactGA.initialize({ trackingId: TRACKING_ID });

const mimeTypesList = mimeTypes.map(mt => mt.type);

const getURL = async (id, fileType, name) => {
    let url = Cookies.get("at") != null ? auth_download_url : download_url;
    let auth_header = Cookies.get("at") != null ? Cookies.get("at") : search_key;
    const urlToReturn = await fetchURL(`${url}?filename=${id}/${name}`, auth_header);
    return urlToReturn;
}

const compoundClick = "compound_click";
const documentsClick = "documents_click";


/*const ga_event = (event, id) => {
    console.log("ga_event called ",event+" id "+id)
    ReactGA.event({
        category: event,
        action: event + "_" + id
    });
}*/

const fetchURL = async (url, auth_header) => {
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${auth_header}`
        }
    })
    const data = await res.json();
    const downloadurl = data[0].downloadurl;
    const fileName = data[0].fileName;
    return downloadurl; //+ `/${fileName}`;

}

const downloadCompoundDocuments = async (event, siblings, setLoading) => {
    event.preventDefault();
    const filesToDownload = [siblings.map(res => res.data.id.raw)];

    const urls = []
    siblings.map((r, index) => {
        urls[index] = { url: r.data.url, filename: r.data.name.raw }
    });
    const zip = new JSZip();
    let count = 0;
    let fileName = siblings[0].data.name.raw.substring(0, siblings[0].data.name.raw.indexOf("."))
    const zipFilename = `techdocsCompound-${fileName}.zip`;

    urls.forEach(async (result) => {
        let url = result.url + ''
        try {
            const file = await JSZipUtils.getBinaryContent(url)
            zip.file(result.filename, file, { binary: true });
            count++;
            if (count === urls.length) {
                zip.generateAsync({ type: 'blob' }).then(function (content) {
                    saveAs(content, zipFilename);
                });
            }
            setLoading(false)
        } catch (err) {
            console.log(err);
            setLoading(false)
        }
    });

}

// This func is for downloading the document
const documentClick = async (id, fileType, name, evt, isUserAuthenticated, access_Token, setLoading, viewdoc, error_Download_Message_, clickType) => {
    if (isUserAuthenticated === undefined && Cookies.get("at") != null) {
        isUserAuthenticated = true;
        access_Token = Cookies.get("at")
    }
    setLoading(true);
    let url = isUserAuthenticated ? auth_download_url : download_url;
    let auth_header = isUserAuthenticated ? access_Token : search_key;
    // console.log('isUserAuthenticated=' + isUserAuthenticated + ' url=' + url + ' auth_header=' + auth_header)
    evt.preventDefault();

    const encodedName = encodeURI(name);

    console.log("Encoded File Name: :", encodedName);

    fetch(`${url}?filename=${id}/${encodedName}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${auth_header}`
        }
    }).then(response => response.json())
        .then(data => {
            const downloadurl = data[0].downloadurl;
            if (downloadurl) {
                fetch(downloadurl, {
                    method: 'GET'
                }).then(response => response.blob())
                    .then(blob => {
                        // 2. Create blob link to download
                        var mimeType = mimeTypes.filter(mt => mt.type === fileType.toLowerCase());
                        var file = new Blob([blob], { type: mimeType[0].value });
                        //var file = new Blob([blob], { type: `application/${fileType}` });
                        var url = URL.createObjectURL(file);
                        //const url = window.URL.createObjectURL(new Blob([blob]));
                        const link = document.createElement('a');
                        link.href = url;
                        const fileNameOnly = name.split('.').slice(0, -1).join('.');
                        // 2.1 show only if download is clicked
                        if (!viewdoc || !supportedMimeTypes.includes((fileType).toLowerCase())) {
                            if (name.toUpperCase().endsWith(fileType.toUpperCase()))
                                link.setAttribute('download', `${name}`);
                            else
                                link.setAttribute('download', `${fileNameOnly}.${fileType}`);
                            link.setAttribute('target', '_blank');
                            //ga_event('download', name)
                        }
                        else {
                            link.setAttribute('target', '_blank');
                            link.setAttribute('rel', 'noopener noreferrer');
                            //ga_event('view', name)
                        }
                        // 3. Append to html page
                        document.body.appendChild(link);
                        // 4. Force download
                        link.click();
                        // 5. Clean up and remove the link
                        link.parentNode.removeChild(link);
                        setLoading(false)
                        return true;
                    })
                    .catch(err => {
                        console.error('Request failed', err)
                        setLoading(false)
                    })
            }
            else {
                console.error("download url is null for ", id)
                setLoading(false)
                alert(error_Download_Message_ + id);
            }
        })
}


// This func is for viewing the document
const documentView = async (id, fileType, name, evt, isUserAuthenticated, access_Token, setLoading, viewdoc, error_Download_Message_, clickType) => {
    if (isUserAuthenticated === undefined && Cookies.get("at") != null) {
        isUserAuthenticated = true;
        access_Token = Cookies.get("at")
    }

    setLoading(true);

    let url = isUserAuthenticated ? auth_download_url : download_url;
    let auth_header = isUserAuthenticated ? access_Token : search_key;

    // console.log('isUserAuthenticated=' + isUserAuthenticated + ' url=' + url + ' auth_header=' + auth_header)

    evt.preventDefault();

    const encodedName = encodeURI(name);

    console.log("Encoded File Name: :", encodedName);


    fetch(`${url}?filename=${id}/${encodedName}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${auth_header}`
        }
    })
        .then(response => response.json())
        .then(data => {
            const downloadurl = data[0].downloadurl;

            if (downloadurl) {
                fetch(downloadurl, {
                    method: 'GET'
                })
                    .then(response => response.blob())
                    .then(blob => {
                        // 2. Create blob link to download
                        var mimeType = mimeTypes.filter(mt => mt.type === fileType.toLowerCase());
                        var file = new Blob([blob], { type: mimeType[0].value });

                        //var file = new Blob([blob], { type: `application/${fileType}` });
                        var url = URL.createObjectURL(file);
                        const link = document.createElement('a');

                        link.href = url;
                        link.target = "_blank";
                        link.click();



                        return true;
                    })
                    .catch(err => {
                        console.error('Request failed', err);
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
            else {
                console.error("download url is null for ", id)
                setLoading(false)
                alert(error_Download_Message_ + id);
            }
        })
}


// This func is also used to download document
const compoundDocumentClick = async (id, fileType, name, evt, isUserAuthenticated, access_Token, setLoading, parentid, recordid, viewdoc, error_Download_Message_, clickType) => {
    if (isUserAuthenticated === undefined && Cookies.get("at") != null) {
        isUserAuthenticated = true;
        access_Token = Cookies.get("at")
    }
    setLoading(true);
    let url = Cookies.get("at") != null ? auth_url : public_url;
    let auth_header = isUserAuthenticated ? access_Token : search_key;
    evt.preventDefault();
    var recordToDownload = null;
    var client = ElasticAppSearch.createClient({ searchKey: auth_header, endpointBase: url, engineName: engineName });
    var fileNameToLookFor = recordid + '.pdf';
    client.search("", {
        page: { size: 100 },
        filters: { all: [{ parenttype: "CompoundDocument" }, { parentid: parentid }] },
        result_fields: { name: { raw: {} }, title: { raw: {} }, description: { raw: {} }, revision: { raw: {} }, filename: { raw: {} }, createdate: { raw: {} }, language: { raw: {} }, datasize: { raw: {} }, filetype: { raw: {} }, parentid: { raw: {} }, parenttype: { raw: {} }, id: { raw: {} } },
    }).then(resultList => {

        resultList.results.map(r => {
            if (r.data.name && (r.data.name.raw).toLowerCase() === fileNameToLookFor.toLocaleLowerCase()) {
                recordToDownload = r;
            }
            return r;
        })
        url = isUserAuthenticated ? auth_download_url : download_url;
        id = recordToDownload.data.id.raw;
        name = recordToDownload.data.name.raw;
        fileType = recordToDownload.data.filetype.raw

        const encodedName = encodeURI(name);

        console.log("Encoded File Name: :", encodedName);
    
        
        // console.log('id ', id + " name " + name + " fileType " + fileType)
        fetch(`${url}?filename=${id}/${encodedName}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${auth_header}`
            }
        }).then(response => response.json())
            .then(data => {
                setLoading(false);
                const downloadurl = data[0].downloadurl;
                if (downloadurl) {
                    fetch(downloadurl, {
                        method: 'GET'
                    }).then(response => response.blob())
                        .then(blob => {
                            // 2. Create blob link to download
                            var file = new Blob([blob], { type: `application/${fileType}` });
                            var url = URL.createObjectURL(file);
                            //const url = window.URL.createObjectURL(new Blob([blob]));
                            const link = document.createElement('a');
                            link.href = url;
                            if (!viewdoc) {
                                if (name.toUpperCase().endsWith(fileType.toUpperCase()))
                                    link.setAttribute('download', `${name}`);
                                else
                                    link.setAttribute('download', `${name}.${fileType}`);
                                link.setAttribute('target', '_blank');
                            }
                            else {
                                link.setAttribute('target', '_blank');
                                link.setAttribute('rel', 'noopener noreferrer');
                            }
                            // 3. Append to html page
                            document.body.appendChild(link);
                            // 4. Force download
                            link.click();
                            // 5. Clean up and remove the link
                            link.parentNode.removeChild(link);
                            setLoading(false)
                            return true;
                        })
                        .catch(err => {
                            console.error('Request failed', err)
                            setLoading(false)
                        })
                }
                else {
                    console.error("comp download url is null for ", id)
                    setLoading(false)
                    alert(error_Download_Message_ + id);
                }
            })
    })
        .catch(error => {
            console.log(`error: ${error}`);
            setLoading(false)
        });
}


// This func is also used to view Document
const compoundDocumentView = async (id, fileType, name, evt, isUserAuthenticated, access_Token, setLoading, parentid, recordid, viewdoc, error_Download_Message_, clickType) => {
    if (isUserAuthenticated === undefined && Cookies.get("at") != null) {
        isUserAuthenticated = true;
        access_Token = Cookies.get("at")
    }
    setLoading(true);
    let url = Cookies.get("at") != null ? auth_url : public_url;
    let auth_header = isUserAuthenticated ? access_Token : search_key;
    console.log('isUserAuthenticated=' + isUserAuthenticated + ' url=' + url + ' auth_header=' + auth_header)
    evt.preventDefault();
    var recordToDownload = null;
    var client = ElasticAppSearch.createClient({ searchKey: auth_header, endpointBase: url, engineName: engineName });
    var fileNameToLookFor = recordid + '.pdf';
    client.search("", {
        page: { size: 100 },
        filters: { all: [{ parenttype: "CompoundDocument" }, { parentid: parentid }] },
        result_fields: { name: { raw: {} }, title: { raw: {} }, description: { raw: {} }, revision: { raw: {} }, filename: { raw: {} }, createdate: { raw: {} }, language: { raw: {} }, datasize: { raw: {} }, filetype: { raw: {} }, parentid: { raw: {} }, parenttype: { raw: {} }, id: { raw: {} } },
    }).then(resultList => {

        resultList.results.map(r => {
            if (r.data.name && (r.data.name.raw).toLowerCase() === fileNameToLookFor.toLocaleLowerCase()) {
                recordToDownload = r;
            }
            return r;
        })
        url = isUserAuthenticated ? auth_download_url : download_url;
        id = recordToDownload.data.id.raw;
        name = recordToDownload.data.name.raw;
        fileType = recordToDownload.data.filetype.raw
        // console.log('id ', id + " name " + name + " fileType " + fileType)

        const encodedName = encodeURI(name);

        console.log("Encoded File Name: :", encodedName);
    

        fetch(`${url}?filename=${id}/${encodedName}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${auth_header}`
            }
        }).then(response => response.json())
            .then(data => {
                setLoading(false);
                const downloadurl = data[0].downloadurl;
                if (downloadurl) {
                    fetch(downloadurl, {
                        method: 'GET'
                    }).then(response => response.blob())
                        .then(blob => {
                            // 2. Create blob link to download
                            var file = new Blob([blob], { type: `application/${fileType}` });
                            var url = URL.createObjectURL(file);

                            const link = document.createElement('a');

                            link.href = url;
                            link.target = "_blank";

                            link.click();


                            setLoading(false);

                            return true;
                        })
                        .catch(err => {
                            console.error('Request failed', err)
                            setLoading(false)
                        })
                }
                else {
                    console.error("comp download url is null for ", id)
                    setLoading(false)
                    alert(error_Download_Message_ + id);
                }
            })
    })
        .catch(error => {
            console.log(`error: ${error}`);
            setLoading(false)
        });
}





function siblings(results, parentid) {
    let siblingresults = [];
    results.map((result, index) => {
        //console.log('result single ',result)
        //console.log(result.parentid.raw+'=='+parentid)
        if (result.parentid.raw === parentid)
            siblingresults[siblingresults.length] = result
    })
    //console.log("sibling results for ", parentid + " siblings " + siblingresults.length)
    return siblingresults;
}

const getSiblingsDocs = async (id) => {
    //const bodyData = {"query": id,    }
    const res = await fetch(public_url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "query": id,
        })
    });
    const data = await res.json();
    const results = await data.results;
    // console.log('sib results ', results)
    return results;

}

const getSiblings = async (id) => {

    fetch(public_url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'query': id,
        })
    }).then(response => response.json())
        .then(data => {
            // console.log('data ', data)
            // console.log('results', data.results)
            return data.results;
        })
}

const Documents = ({ children }) => (
    <div id="results">
        {children}
    </div>
)
const DocumentResult = ({ result: doc, showCollapse, compoundToggle, isEmployee, isUserAuthenticated, resultsSelected, results, handleOnResultChange, access_Token, altDispValues, trackClickThrough, t, load, setLoading, srchTrm }) => {
    // const [open, setOpen] = useState(false);
    // const [sharedLink, setSharedLink] = useState([]);
    const [copyText, setCopyText] = useState(false);
    var itemSelected = false;
    // const isSharedLinkCopy = false;
    // const urlParams = new URLSearchParams(window.location.search);
    // const [compoundSiblings, setCompoundSiblings] = useState();

    // const handleOpen = () => {


    // };
    // useEffect(() => {

    // }, [])

    const copyToClipboard = (document) => {
        // handleOpen();

        let countryName = Cookies.get("Techdocs-Country");
        let languageName = Cookies.get("Techdocs-Language");
        let shareUrlLink = new URL(window.location.href);

        let newUrl = new URL('/', shareUrlLink.origin);


        // deleting the bookmark filter param if any 
        // shareUrlLink.searchParams.delete('bm');
        // shareUrlLink.searchParams.delete('isauthenticated');
        // shareUrlLink.searchParams.delete('current');

        newUrl.searchParams.set('country', countryName);
        newUrl.searchParams.set('language', languageName);
        newUrl.searchParams.set('docId', document.id.raw);
        // if(document.audience.raw == 'Public') {
        newUrl.searchParams.set('isauthenticated', document.audience.raw.toLowerCase());
        // console.log("status1", document.audience.raw);
        // } else if(document.audience.raw == 'Internal' && isUserAuthenticated) {
        //     shareUrlLink.searchParams.set('isAuthenticated', 'audience');
        //     console.log("status", document.audience.raw, isUserAuthenticated);
        // } else {
        //     shareUrlLink.searchParams.set('isAuthenticated', 'audience');
        //     console.log("status3", document.audience.raw, isUserAuthenticated);
        // }
        // setSharedLink(shareUrlLink);
        if (newUrl.href !== undefined) {
            setCopyText(true);
            copy(newUrl?.href);
            setTimeout(() => {
                setCopyText(false);
            }, 1500)
        }

    }
    resultsSelected.map(element => {
        if (element.id === doc.id.raw)
            itemSelected = true;
        return element;
    })

    var samecat = 0
    if (Cookies.get("cat") === getFilterValueDisplay(altDispValues[doc.document_classification.raw] !== undefined ? altDispValues[doc.document_classification.raw] : t(doc.document_classification.raw))
        && Cookies.get("subcat") === getFilterValueDisplay(altDispValues[doc.document_classification_subcategory.raw] !== undefined ? altDispValues[doc.document_classification_subcategory.raw] : t(doc.document_classification_subcategory.raw))) {
        samecat = 1
    }
    Cookies.set("cat", getFilterValueDisplay(altDispValues[doc.document_classification.raw] !== undefined ? altDispValues[doc.document_classification.raw] : t(doc.document_classification.raw)));
    Cookies.set("subcat", getFilterValueDisplay(altDispValues[doc.document_classification_subcategory.raw] !== undefined ? altDispValues[doc.document_classification_subcategory.raw] : t(doc.document_classification_subcategory.raw)));

    const title = doc.title.raw.replace(/&amp;/gi, '&').replace(/&shy;/gi, '­');
    const smTitleLimit = 40;
    // const smTitle = title.length > smTitleLimit ? title.substring(0, smTitleLimit) + "..." : title;
    const docSize = getFilterValueDisplay(Math.round(doc.datasize.raw / 1024));
    const docType = getFilterValueDisplay((doc.filetype.raw).toUpperCase());
    const recordId = doc.recordid && doc.recordid.raw ? doc.recordid.raw : doc.name.raw;
    let siblingDocs = [];
    // let hideCheckBox = false; // don't show checkbox for compound documents
    // results.forEach(documet => {
    //     if (documet.parenttype && documet.parenttype.raw === 'CompoundDocument' && compoundToggle == true) {


    //         var url = Cookies.get("at") != null ? auth_url : public_url;
    //         var key = Cookies.get("at") != null ? Cookies.get("at") : search_key;


    //     }
    // })

    const docDate = new Date(doc?.expirationdate?.raw?.split('T')[0]);
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const isExpired = docDate.getTime() <= new Date(`${year}-${month}-${day}`).getTime()

    return (
        <ModalContext.Consumer>
            {({ openModal, updateModal, updatedUserStatus, updateSiblings, updateAltDispValues }) => {
                const hasDocModal = true;
                const openDetailsModal = (documentx) => {
                    // console.log("openModelMethod")
                    updateModal(documentx);
                    openModal();
                    updatedUserStatus(isEmployee);
                    updateSiblings(siblingDocs);
                    updateAltDispValues(altDispValues)
                };
                //const clickAction = hasDocModal ? { onClick: openDetailsModal } : '';
                return (
                    <>
                        {(!showCollapse && samecat === 0) &&
                            <p style={{ marginBottom: '10px' }}>
                                <div className='header-teal'>{getFilterValueDisplay(altDispValues[doc.document_classification.raw] !== undefined ? altDispValues[doc.document_classification.raw] : t(doc.document_classification.raw))} /&nbsp;
                                    {getFilterValueDisplay(altDispValues[doc.document_classification_subcategory.raw] !== undefined ? altDispValues[doc.document_classification_subcategory.raw] : t(doc.document_classification_subcategory.raw))}</div>
                            </p>}


                        {!showCollapse &&

                            <div style={{ background: '0% 0% no-repeat padding-box padding-box rgb(243, 246, 247)' }}>
                                <div className={styles.collapsedView}>
                                    <div style={{ padding: '0 10px', flexGrow: '1', display: 'flex', alignItems: 'flex-start' }}>
                                        <input style={{ marginRight: '5px' }} type="checkbox" className="download-checkbox-test" id={`checkbox-${doc.id.raw}`} checked={itemSelected}
                                            onChange={() => { handleOnResultChange(doc.id.raw, doc.filetype.raw, doc.name.raw); trackClickThrough(doc._meta.id) }} />
                                        <span style={{ padding: '0 10px' }} className='block-main-title'>

                                            {(doc.solution.raw !== null && doc.solution.raw !== '' && doc.solution.raw !== '-') && <span>{t('Solution')} #{doc.solution.raw} - </span>}   {title}
                                            {
                                                isExpired &&
                                                <span className='expired-color'> &#40;{t("Expired")}&#41;</span>
                                            }
                                        </span>

                                    </div>
                                    <div>
                                        {/* <span>{siblingDocs.</span> */}
                                        {/* <p className={styles.formHeader} style={{fontSize:'14px',textAlign:'right', marginRight:'10px'}}><span title={t('Published_Date')}><FaCalendarAlt style={{fontSize:'13px', marginBottom:'3px'}}/> {doc.publisheddate.raw}</span></p> */}
                                        <p className={styles.blockMainTitle} style={{ whiteSpace: 'nowrap', paddingRight: '25px' }}>
                                            V {doc.revision.raw}
                                            <span >
                                                <Button className="button-align" id={`btn-details-${doc.id.raw}`} style={{ border: "0px", padding: '0px' }} onClick={() => openDetailsModal(doc)} >
                                                    <GoInfo style={{ fontSize: '17px' }} />
                                                </Button>
                                            </span>
                                            {/* <span className="align-right douc-info ortho-red docsize-info"> {docType} | {docSize > 1024 ? (parseInt((docSize / 1024).toFixed(2))) + ' MB' : docSize + ' KB'}

                                            </span> */}
                                            {doc && doc.parenttype.raw !== 'CompoundDocument' &&
                                                <span
                                                    className="align-right douc-info ortho-red docsize-info"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={(evt) => {
                                                        setLoading(!load);
                                                        documentClick(doc.id.raw, doc.filetype.raw, doc.name.raw, evt, isUserAuthenticated, access_Token, setLoading, true, t('error_Download_Message_'), documentsClick);

                                                        trackDocData(doc?.id?.raw, doc?.title?.raw, document?.recordid?.raw, documentsClick);
                                                    }}
                                                >
                                                    {docType} | {docSize > 1024 ? (parseInt((docSize / 1024).toFixed(2))) + ' MB' : docSize + ' KB'}
                                                </span>
                                            }
                                            {doc && doc.parenttype.raw === 'CompoundDocument' &&
                                                <span
                                                    className="align-right douc-info ortho-red docsize-info"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={(evt) => {
                                                        setLoading(load);
                                                        compoundDocumentClick(doc.id.raw, doc.filetype.raw, doc.name.raw, evt, isUserAuthenticated, access_Token, setLoading, doc.parentid.raw, doc.recordid.raw, true, t('error_Download_Message_'), compoundClick);

                                                        trackDocData(doc?.id?.raw, doc?.title?.raw, document?.recordid?.raw, compoundClick);
                                                    }}
                                                >
                                                    PDF | {docSize > 1024 ? (parseInt((docSize / 1024).toFixed(2))) + ' MB' : docSize + ' KB'}
                                                </span>}
                                            <a> <FaRegCopy title={t('Copy share link')} style={{ border: "none", cursor: "pointer" }} className="ortho-red" onClick={() => copyToClipboard(doc)} /></a> {copyText && <span className={styles.sharedText}>{t('Copied')}</span>}
                                            {doc && doc.parenttype.raw !== 'CompoundDocument' &&
                                                <span>
                                                    <a
                                                        href='#'
                                                        style={{ paddingLeft: '5px' }}
                                                        onClick={(evt) => {
                                                            setLoading(!load);

                                                            documentClick(doc.id.raw, doc.filetype.raw, doc.name.raw, evt, isUserAuthenticated, access_Token, setLoading, true, t('error_Download_Message_'), documentsClick);

                                                            trackDocData(doc?.id?.raw, doc?.title?.raw, document?.recordid?.raw, documentsClick);
                                                        }}
                                                        target="_blank"
                                                    >
                                                        <BiGlasses title={t('View Document')} />
                                                    </a>
                                                </span>}
                                            {doc && doc.parenttype.raw === 'CompoundDocument' &&
                                                <span>
                                                    <a
                                                        href='#'
                                                        style={{ paddingLeft: '5px' }}
                                                        onClick={(evt) => {
                                                            setLoading(!load);

                                                            compoundDocumentClick(doc.id.raw, doc.filetype.raw, doc.name.raw, evt, isUserAuthenticated, access_Token, setLoading, doc.parentid.raw, doc.recordid.raw, true, t('error_Download_Message_'), compoundClick);

                                                            trackDocData(doc?.id?.raw, doc?.title?.raw, document?.recordid?.raw, compoundClick);
                                                        }}
                                                        target="_blank"
                                                    >
                                                        <BiGlasses title={t('   ')} />
                                                    </a>
                                                </span>}


                                            <span className={styles.testingthis} >
                                                {/* <a href='#' style={{ paddingLeft: '10px' }} onClick={(evt) => { setLoading(!load); documentClick(doc.id.raw, doc.filetype.raw, doc.name.raw, evt, isUserAuthenticated, access_Token, setLoading, false, t('error_Download_Message_')); }} download={doc.id.raw} target="_blank" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                        <g fill="none" fillRule="evenodd">
                                                            <g fill="#CF2F44" transform="translate(-864 -99)">
                                                                <path d="M885.213161 115.042063H888V123h-24v-7.957937h2.787097v5.229502h18.426064v-5.229502zM874.606632 99h2.787097v12.328486l4.541936-4.446592 1.961032 1.945526-7.896516 7.730567-7.896775-7.730567 1.961291-1.945526 4.541935 4.446592V99z" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </a> */}
                                                {doc && doc.parenttype.raw === 'CompoundDocument' &&
                                                    <a href='#' title={t('Download Document')}
                                                        style={{ paddingLeft: '5px' }}
                                                        onClick={(evt) => {
                                                            setLoading(!load);

                                                            compoundDocumentClick(doc.id.raw, doc.filetype.raw, doc.name.raw, evt, isUserAuthenticated, access_Token, setLoading, doc.parentid.raw, doc.recordid.raw, false, t('error_Download_Message_'), compoundClick);

                                                            trackDocData(doc?.id?.raw, doc?.title?.raw, document?.recordid?.raw, compoundClick);
                                                        }}
                                                        download={doc.id.raw}
                                                        target="_blank"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                            <g fill="none" fillRule="evenodd">
                                                                <g fill="#3E318F" transform="translate(-864 -99)">
                                                                    <path d="M885.213161 115.042063H888V123h-24v-7.957937h2.787097v5.229502h18.426064v-5.229502zM874.606632 99h2.787097v12.328486l4.541936-4.446592 1.961032 1.945526-7.896516 7.730567-7.896775-7.730567 1.961291-1.945526 4.541935 4.446592V99z" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </a>
                                                }
                                                {doc && doc.parenttype.raw !== 'CompoundDocument' &&
                                                    <a
                                                        href='#'
                                                        title={t('Download Document')}
                                                        style={{ paddingLeft: '5px' }}
                                                        onClick={(evt) => {
                                                            setLoading(!load);

                                                            documentClick(doc.id.raw, doc.filetype.raw, doc.name.raw, evt, isUserAuthenticated, access_Token, setLoading, false, t('error_Download_Message_'), documentsClick);

                                                            trackDocData(doc?.id?.raw, doc?.title?.raw, document?.recordid?.raw, documentsClick);
                                                        }}
                                                        download={doc.id.raw}
                                                        target="_blank"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                            <g fill="none" fillRule="evenodd">
                                                                <g fill="#3E318F" transform="translate(-864 -99)">
                                                                    <path d="M885.213161 115.042063H888V123h-24v-7.957937h2.787097v5.229502h18.426064v-5.229502zM874.606632 99h2.787097v12.328486l4.541936-4.446592 1.961032 1.945526-7.896516 7.730567-7.896775-7.730567 1.961291-1.945526 4.541935 4.446592V99z" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </a>
                                                }
                                            </span>

                                        </p>
                                    </div>

                                </div>
                                {
                                    doc && doc.parenttype.raw === 'CompoundDocument' && compoundToggle === true &&
                                    <GetCompoundDoc
                                        compoundDoc={doc}
                                        engineName={engineName}
                                        downloadDoc={documentClick}
                                        setLoading={setLoading}
                                        documentView={documentView}
                                        t={t}
                                    />
                                }
                                {
                                    doc.description && doc.description.raw.length > 0 &&
                                    <p
                                        style={{ marginLeft: '35px' }}
                                        className="document-description"
                                    >
                                        {getFilterValueDisplay(doc.description.raw)}
                                    </p>

                                }
                                {doc.referencelist && doc.referencelist.raw && doc.referencelist.raw.length > 0 &&
                                    <div>
                                        {doc.referencelist.raw.map((references, index) => {
                                            let reference = null
                                            try {
                                                reference = JSON.parse(references)
                                            } catch (e) { console.error("error occurred reference", e) }

                                            const referenceFileType = reference.document_name.split('.').pop().toLowerCase();
                                            return (
                                                <>
                                                    {index === 0 && reference.reference_id && reference.reference_id.length > 1 && <p style={{ marginLeft: '35px', fontWeight: "600" }} className="Ortho-p">{t('Supporting Documents')} </p>}
                                                    {reference.reference_id && reference.reference_id.length > 1 && mimeTypesList.includes(referenceFileType) &&
                                                        <li
                                                            style={{ paddingLeft: '60px' }}
                                                        >
                                                            <a
                                                                href='#'
                                                                className='indigo'
                                                                style={{ fontSize: '14px' }}
                                                                onClick={(evt) => {
                                                                    setLoading(!load);

                                                                    documentClick(reference.reference_id, referenceFileType, reference.document_name, evt, isEmployee, Cookies.get("at"), setLoading, true, t('error_Download_Message_'), documentsClick);

                                                                    trackDocData(doc?.id?.raw, doc?.title?.raw, document?.recordid?.raw, documentsClick);
                                                                }}
                                                                download={reference.reference_id}
                                                                target="_blank"
                                                            >
                                                                {reference.reference_title}
                                                            </a>
                                                        </li>
                                                    }
                                                </>)
                                        })}
                                    </div>}

                            </div>}



                        {/* this is expended view Area */}
                        <div>


                            <div style={{ marginBottom: '10px' }} id={`label-${doc.id.raw}`} dataproduct={doc.equipmentlist.raw} datacategory={doc.document_classification.raw} htmlFor={`checkbox-${doc.id.raw}`}>
                                <div style={{ background: '#F3F6F7 0% 0% no-repeat padding-box', borderRadius: '8px' }} className="tech-docs--documents-download-l" >
                                    {showCollapse && <div>
                                        <div >
                                            <p className={styles.formHeader} style={{ wordBreak: 'break-word' }}>
                                                {recordId}<small> | {t(doc.language.raw)} | <span>V {doc.revision.raw}</span>{doc.parenttype && doc.parenttype.raw !== "CompoundDocument" && doc._group && doc._group.map((revision, index) => {
                                                    return (
                                                        <>| <span><a href="#" onClick={() => { openDetailsModal(revision); }}>V {revision.revision.raw}</a></span> </>

                                                    )
                                                }
                                                )}
                                                </small>

                                            </p>
                                            {
                                                isExpired &&
                                                <span className='expired-tag expired-color'>{t("Expired")}</span>
                                            }

                                            {/* <p className={styles.formHeader} style={{fontSize:'14px',textAlign:'right', marginRight:'10px'}}><span title={t('Published_Date')}><FaCalendarAlt style={{fontSize:'13px', marginBottom:'3px'}}/> {doc.publisheddate.raw}</span></p> */}
                                        </div>

                                        <div className={styles.expandedView} style={{ padding: '0 10px', flexGrow: '1', display: 'flex', alignItems: 'flex-start' }}>
                                            <input style={{ marginRight: '5px' }} type="checkbox" className="download-checkbox-test" id={`checkbox-${doc.id.raw}`} checked={itemSelected}
                                                onChange={() => { handleOnResultChange(doc.id.raw, doc.filetype.raw, doc.name.raw); trackClickThrough(doc._meta.id) }} />
                                            <span style={{ padding: '0 10px', fontSize: '18px' }} >
                                                {(doc.solution.raw !== null && doc.solution.raw !== "" && doc.solution.raw !== '-') && <b>{t('Solution')} #{doc.solution.raw} - </b>}
                                                <b>{title}</b>&nbsp;&nbsp;{doc.audience && doc.audience.raw === 'Internal' && <CgInternal className='indigo' title={t('Internal only')} />}

                                            </span>
                                        </div>

                                        {/* This part includes details click and doc title, download, etc on top-right side */}
                                        <div style={{ display: "grid", gridTemplateColumns: "4fr 5fr" }}>

                                            <span
                                                className={styles.details}
                                                id={`btn-details-${doc.id.raw}`}
                                                style={{ border: "0px" }}
                                                onClick={() => openDetailsModal(doc)}
                                            >
                                                {t('Details')}
                                            </span>


                                            {/* This part is top-right side document name, download icon, etc */}
                                            <p className={styles.details} style={{ margin: '10px', textAlign: "right" }}>

                                                {/* This is the Document name on the top-right side without clicking details FOR NOT COMPOUND DOCs */}
                                                {
                                                    doc && doc.parenttype.raw !== 'CompoundDocument' &&
                                                    <span
                                                        className="align-right douc-info ortho-red docsize-info"
                                                        onClick={(evt) => {
                                                            setLoading(!load);

                                                            documentClick(doc.id.raw, doc.filetype.raw, doc.name.raw, evt, isUserAuthenticated, access_Token, setLoading, true, t('error_Download_Message_'), documentsClick);

                                                            trackDocData(doc?.id?.raw, doc?.title?.raw, doc?.recordid?.raw, documentsClick);
                                                        }}
                                                    >
                                                        {docType} | {docSize > 1024 ? (parseInt((docSize / 1024).toFixed(2))) + ' MB' : docSize + ' KB'}
                                                    </span>
                                                }

                                                {/* This is the Document name on the top-right side without clicking details ONLY for COMPOUND DOCs */}
                                                {
                                                    doc && doc.parenttype.raw === 'CompoundDocument' &&
                                                    <span
                                                        className="align-right douc-info ortho-red docsize-info"
                                                        onClick={(evt) => {
                                                            setLoading(load);

                                                            compoundDocumentClick(doc.id.raw, doc.filetype.raw, doc.name.raw, evt, isUserAuthenticated, access_Token, setLoading, doc.parentid.raw, doc.recordid.raw, true, t('error_Download_Message_'), compoundClick);

                                                            trackDocData(doc?.id?.raw, doc?.title?.raw, doc?.recordid?.raw, compoundClick);
                                                        }}
                                                    >
                                                        PDF | {docSize > 1024 ? (parseInt((docSize / 1024).toFixed(2))) + ' MB' : docSize + ' KB'}
                                                    </span>
                                                }




                                                {/* This is the copy icon on the top-right side without clicking detailss */}
                                                <a>
                                                    <FaRegCopy
                                                        title={t('Copy share link')}
                                                        onClick={() => copyToClipboard(doc)}
                                                    />
                                                </a>
                                                {
                                                    copyText &&
                                                    <span className={styles.sharedText}>
                                                        {t('Copied')}
                                                    </span>
                                                }



                                                {/* This is the GLASS view icon on the top-right side without clicking details - NOT Compound DOC */}
                                                {
                                                    doc && doc.parenttype.raw !== 'CompoundDocument' &&
                                                    <span>
                                                        <a
                                                            href='#'
                                                            style={{ paddingLeft: '10px' }}
                                                            onClick={(evt) => {
                                                                setLoading(!load);

                                                                documentClick(doc.id.raw, doc.filetype.raw, doc.name.raw, evt, isUserAuthenticated, access_Token, setLoading, true, t('error_Download_Message_'), documentsClick);

                                                                trackDocData(doc?.id?.raw, doc?.title?.raw, doc?.recordid?.raw, documentsClick);
                                                            }}
                                                            download={doc.recordid.raw}
                                                            target="_blank"
                                                        >
                                                            <BiGlasses title={t('View Document')} />
                                                        </a>
                                                    </span>
                                                }

                                                {/* This is the GLASS view icon on the top-right side without clicking details FOR COMPOUND DOC */}
                                                {
                                                    doc && doc.parenttype.raw === 'CompoundDocument' &&
                                                    <span>
                                                        <a
                                                            href='#'
                                                            style={{ paddingLeft: '10px' }}
                                                            onClick={(evt) => {
                                                                setLoading(!load);

                                                                compoundDocumentClick(doc.id.raw, doc.filetype.raw, doc.name.raw, evt, isUserAuthenticated, access_Token, setLoading, doc.parentid.raw, doc.recordid.raw, true, t('error_Download_Message_'), compoundClick);

                                                                trackDocData(doc?.id?.raw, doc?.title?.raw, doc?.recordid?.raw, compoundClick);
                                                            }}
                                                            target="_blank"
                                                        >
                                                            <BiGlasses title={t('View Document')} />
                                                        </a>
                                                    </span>
                                                }



                                                <span className={styles.testingthis} >

                                                    {/* This is the DOWNLOAD icon on the top-right side without clicking details FOR COMPOUND DOC */}
                                                    {
                                                        doc && doc.parenttype.raw === 'CompoundDocument' &&
                                                        <a
                                                            href='#'
                                                            title={t('Download Document')}
                                                            style={{ paddingLeft: '10px' }}
                                                            onClick={(evt) => {
                                                                setLoading(!load);

                                                                compoundDocumentClick(doc.id.raw, doc.filetype.raw, doc.name.raw, evt, isUserAuthenticated, access_Token, setLoading, doc.parentid.raw, doc.recordid.raw, false, t('error_Download_Message_'), compoundClick);

                                                                trackDocData(doc?.id?.raw, doc?.title?.raw, doc?.recordid?.raw, compoundClick);
                                                            }}
                                                            download={doc.id.raw}
                                                            target="_blank"
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                <g fill="none" fillRule="evenodd">
                                                                    <g fill="#3E318F" transform="translate(-864 -99)">
                                                                        <path d="M885.213161 115.042063H888V123h-24v-7.957937h2.787097v5.229502h18.426064v-5.229502zM874.606632 99h2.787097v12.328486l4.541936-4.446592 1.961032 1.945526-7.896516 7.730567-7.896775-7.730567 1.961291-1.945526 4.541935 4.446592V99z" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>
                                                    }

                                                    {/* This is the DOWNLOAD icon on the top-right side without clicking details - NOT COMPOUND DOC */}
                                                    {
                                                        doc && doc.parenttype.raw !== 'CompoundDocument' &&
                                                        <a
                                                            href='#'
                                                            title={t('Download Document')}
                                                            style={{ paddingLeft: '10px' }}
                                                            onClick={(evt) => {
                                                                setLoading(!load);

                                                                documentClick(doc.id.raw, doc.filetype.raw, doc.name.raw, evt, isUserAuthenticated, access_Token, setLoading, false, t('error_Download_Message_'), documentsClick);

                                                                trackDocData(doc?.id?.raw, doc?.title?.raw, doc?.recordid?.raw, documentsClick);
                                                            }}
                                                            download={doc.id.raw}
                                                            target="_blank"
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                <g fill="none" fillRule="evenodd">
                                                                    <g fill="#3E318F" transform="translate(-864 -99)">
                                                                        <path d="M885.213161 115.042063H888V123h-24v-7.957937h2.787097v5.229502h18.426064v-5.229502zM874.606632 99h2.787097v12.328486l4.541936-4.446592 1.961032 1.945526-7.896516 7.730567-7.896775-7.730567 1.961291-1.945526 4.541935 4.446592V99z" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>
                                                    }
                                                </span>

                                            </p>
                                        </div>


                                        <p style={{ marginBottom: '0px', marginLeft: '35px' }}><small className="ortho-teal">{getFilterValueDisplay(altDispValues[doc.document_classification.raw] !== undefined ? altDispValues[doc.document_classification.raw] : t(doc.document_classification.raw))} /&nbsp;
                                            {getFilterValueDisplay(altDispValues[doc.document_classification_subcategory.raw] !== undefined ? altDispValues[doc.document_classification_subcategory.raw] : t(doc.document_classification_subcategory.raw))}</small>
                                        </p>
                                        <p key={`${doc.id.raw}-${doc.title.raw}`} style={{ marginBottom: '0px', marginLeft: '35px' }}>
                                            {doc.equipmentlist && doc.equipmentlist.raw !== null && doc.equipmentlist.raw.length > 0 &&
                                                <small className="ortho-darkGray">
                                                    {
                                                        doc.equipmentlist.raw.map((eq, index) => {
                                                            return (
                                                                <>
                                                                    {getFilterValueDisplay(altDispValues[eq] !== undefined ? altDispValues[eq] : eq)}
                                                                    {index < doc.equipmentlist.raw.length - 1 && <>,&nbsp;</>}
                                                                </>
                                                            )
                                                        })}
                                                </small>}
                                        </p>


                                        {doc && doc.parenttype.raw === 'CompoundDocument' && compoundToggle === true &&
                                            <GetCompoundDoc
                                                compoundDoc={doc}
                                                engineName={engineName}
                                                downloadDoc={documentClick}
                                                setLoading={setLoading}
                                                documentView={documentView}
                                                t={t}
                                            />}

                                        {doc.description && doc.description.raw.length > 0 &&

                                            <p style={{ marginLeft: '35px' }} className="document-description">{getFilterValueDisplay(doc.description.raw)}</p>
                                        }
                                        {doc.referencelist && doc.referencelist.raw && doc.referencelist.raw.length > 0 &&
                                            <div>
                                                {doc.referencelist.raw.map((references, index) => {
                                                    let reference = null
                                                    try {
                                                        reference = JSON.parse(references)
                                                    } catch (e) { console.error("error occurred reference", e) }
                                                    const referenceFileType = reference.document_name.split('.').pop().toLowerCase();
                                                    return (
                                                        <>
                                                            {index === 0 && reference.reference_id && reference.reference_id.length > 1 && <p style={{ marginLeft: '35px' }} className="Ortho-p">{t('Supporting Documents')} </p>}
                                                            {reference.reference_id && reference.reference_id.length > 1 && mimeTypesList.includes(referenceFileType) &&
                                                                <li style={{ paddingLeft: '35px' }}>
                                                                    <a
                                                                        href='#'
                                                                        className='indigo' style={{ fontSize: '14px' }} onClick={(evt) => {
                                                                            setLoading(!load);

                                                                            documentClick(reference.reference_id, referenceFileType, reference.document_name, evt, isEmployee, Cookies.get("at"), setLoading, true, t('error_Download_Message_'), documentsClick);

                                                                            trackDocData(doc?.id?.raw, doc?.title?.raw, document?.recordid?.raw, documentsClick);
                                                                        }}
                                                                        download={reference.reference_id}
                                                                        target="_blank"
                                                                    >
                                                                        {reference.reference_title}
                                                                    </a>
                                                                </li>
                                                            }
                                                        </>)
                                                })}
                                            </div>}
                                    </div>}
                                </div>

                                {/* <Modal onClose={handleClose}
                                    open={open}
                                    className={styles.shareDocView}>

                                    <div className="container">
                                        <div className='row'>
                                            <div className="col" style={{ marginTop: '5px' }}>
                                                <div>
                                                    <FaTimes style={{ color: '#fff', cursor: 'pointer', float: 'right', fontSize: '16px' }} onClick={handleClose} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr',  marginLeft: '10px' }}>
                                                    <div>
                                                        <input type="text" defaultValue={sharedLink.href} style={{ width: '100%', height: '34px' }} />
                                                    </div>
                                                    <div>
                                                        <Button style={{ height: '34px', borderRadius: '5px', border: '1px solid white', color: 'black', width: '65px', background: 'white', marginLeft: '20px' }} onClick={copyToClipboard}>
                                                            Copy
                                                        </Button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal> */}


                            </div>
                        </div>
                    </>
                );
            }}
        </ModalContext.Consumer >
    );
};

const DetailsModal = ({ document, closeModal, isEmployed, siblingsy, altDispValues }) => {

    const [siblings, setSiblings] = useState();
    const title = document.title && document.title.raw.length > 0 ? document.title.raw.replace(/&amp;/gi, '&').replace(/&shy;/gi, '­') : ''
    const { t } = useTranslation()
    const [load, setLoad] = useState(false)
    const docSize = getFilterValueDisplay(Math.round(document.datasize.raw / 1024));
    const docType = getFilterValueDisplay((document.filetype.raw).toUpperCase());
    // const date = new Date(document.publisheddate.raw).toLocaleString('en', { dateStyle: 'medium' });
    // const expiryDate = document.expirationdate && document.expirationdate.raw.length > 0 ? new Date(document.expirationdate.raw).toLocaleString('en', { dateStyle: 'medium' }) : 'N/A'
    // const publishedDate = document.publisheddate && document.publisheddate.raw.length > 0 ? new Date(document.publisheddate.raw).toLocaleString('en', { dateStyle: 'medium' }) : 'N/A'
    const setLoading = (value) => {
        //console.log("setLoading in the modal called ",value);
        setLoad(value)
    }
    const GetCompound_Documents = newFilters => {
        useEffect(() => {
            var url = Cookies.get("at") != null ? auth_url : public_url;
            var key = Cookies.get("at") != null ? Cookies.get("at") : search_key;
            //url = "https://sandbox-02933b.ent.us-east-1.aws.found.io" //uncomment if connecting to elastic directly
            //key = search_key;//uncomment if connecting to elastic directly
            setLoading(true)

            var client = ElasticAppSearch.createClient({ searchKey: key, endpointBase: url, engineName: engineName });
            client.search("", {
                page: { size: 100 },
                filters: { all: [{ parenttype: "CompoundDocument" }, { parentid: newFilters }] },
                result_fields: { name: { raw: {} }, title: { raw: {} }, description: { raw: {} }, revision: { raw: {} }, filename: { raw: {} }, createdate: { raw: {} }, language: { raw: {} }, datasize: { raw: {} }, filetype: { raw: {} }, parentid: { raw: {} }, parenttype: { raw: {} }, id: { raw: {} } },
            }).then(resultList => {
                resultList.results.map(async r => {
                    r.data.url = await getURL(r.data.id.raw, r.data.filetype.raw, r.data.name.raw);
                    return r;
                })
                setSiblings(resultList.results)
                setLoading(false)
            })
                .catch(error => {
                    console.log(`error: ${error}`);
                    setLoading(false)
                });

        }, [])

    }

    let siblingDocs = false;
    if (document.parenttype && document.parenttype.raw === 'CompoundDocument') {
        GetCompound_Documents(document.parentid.raw)

    }

    return (
        <article className="details-modal" key={`article-${document.id.raw}`}>
            <section className="details-modal__copy">
                {load && <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open
                >
                    <CircularProgress color="inherit" />
                </Backdrop>}
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div style={{ display: 'grid', gridTemplateColumns: '4fr 4fr', marginBottom: '10px' }}>
                                <div>
                                    <h2>{t('Details')}</h2>
                                </div>

                                <div><FaTimes className='indigo' style={{ cursor: 'pointer', float: 'right' }} onClick={closeModal} /></div>
                            </div>
                            {title && <h3>{title} </h3>}
                            <hr></hr>
                            {/* {((document.parenttype.raw != 'CompoundDocument') || siblings != undefined && siblings.length < 2) && */}

                            <div style={{ display: 'grid', gridTemplateColumns: '3fr 3fr 3fr' }}>

                                <div>
                                    <p className="bare-list__item margin-0 margin-right-minimal-2">
                                        <span dltooltip="" data-content="Published">{t('Published_Date')}: {document?.publisheddate?.raw}</span>
                                    </p>
                                    <p className="bare-list__item margin-0 margin-right-minimal-2">
                                        <span dltooltip="" data-content="Published">{t('Expiration_Date')}: {document?.expirationdate?.raw === "9999-12-31T00:00:00+00:00" ? "" : document?.expirationdate?.raw?.split("T")[0]}</span>
                                    </p>


                                </div>

                                <div>
                                    <p className="bare-list__item margin-0 margin-right-minimal-2 ng-star-inserted"><span>{t('Language')}: {getFilterValueDisplay(t(document.language.raw))}</span></p>
                                </div>
                                <div>
                                    <p style={{ textAlign: 'right' }} className="bare-list__item margin-0 margin-right-minimal-2">
                                        <span>
                                            {/* After clicking Details, top-right side doc TITLE - NOT Compound DOCS */}
                                            {
                                                document.parenttype
                                                &&
                                                document.parenttype.raw !== 'CompoundDocument'
                                                &&
                                                <>
                                                    <a href='#'
                                                        onClick={(evt) => {
                                                            setLoading(!load);

                                                            documentView(document.id.raw, document.filetype.raw, document.name.raw, evt, isEmployed, Cookies.get('at'), setLoading, false, "", documentsClick);

                                                            trackDocData(document?.id?.raw, document?.title?.raw, document?.recordid?.raw, documentsClick);
                                                        }}
                                                        download={document.id.raw} target="_blank"
                                                    >
                                                        {docType} {docSize} KB
                                                    </a>

                                                    {/* After clicking Details, top-right side doc DOWNLOAD icon - for NOT Compound DOCS  */}
                                                    <a href='#'
                                                        onClick={(evt) => {
                                                            setLoading(!load);

                                                            documentClick(document.id.raw, document.filetype.raw, document.name.raw, evt, isEmployed, Cookies.get('at'), setLoading, false, "", documentsClick);

                                                            trackDocData(document?.id?.raw, document?.title?.raw, document?.recordid?.raw, documentsClick);
                                                        }}
                                                        download={document.id.raw} target="_blank"
                                                    >
                                                        <FaFileDownload className='indigo' style={{ cursor: 'pointer', paddingLeft: "2px" }} />
                                                    </a>
                                                </>
                                            }


                                            {/* After clicking Details, top-right side doc TITLE - ONLY for Compound DOCS */}
                                            {
                                                document.parenttype
                                                &&
                                                document.parenttype.raw === 'CompoundDocument'
                                                &&
                                                <>
                                                    {
                                                        <a href='#'
                                                            onClick={(evt) => {
                                                                setLoading(!load);

                                                                // if (document?.filetype?.raw?.toLowerCase() === "pdf") {
                                                                //     documentView(document.id.raw, document.filetype.raw, document.name.raw, evt, isEmployed, Cookies.get('at'), setLoading, document.parentid.raw, document.recordid.raw, false, t('error_Download_Message_'), compoundClick);
                                                                // }
                                                                // else {
                                                                //     compoundDocumentClick(document.id.raw, document.filetype.raw, document.name.raw, evt, isEmployed, Cookies.get('at'), setLoading, document.parentid.raw, document.recordid.raw, false, t('error_Download_Message_'), compoundClick);
                                                                // }

                                                                compoundDocumentView(document.id.raw, document.filetype.raw, document.name.raw, evt, isEmployed, Cookies.get('at'), setLoading, document.parentid.raw, document.recordid.raw, false, t('error_Download_Message_'), compoundClick);

                                                                trackDocData(document?.id?.raw, document?.title?.raw, document?.recordid?.raw, compoundClick);
                                                            }}
                                                            target="_blank"
                                                        >
                                                            PDF {docSize} KB
                                                        </a>
                                                    }

                                                    {/* After clicking Details, top-right side doc DOWNLOAD icon - ONLY for Compound DOCS  */}
                                                    <a href='#'
                                                        onClick={(evt) => {
                                                            setLoading(!load);

                                                            compoundDocumentClick(document.id.raw, document.filetype.raw, document.name.raw, evt, isEmployed, Cookies.get('at'), setLoading, document.parentid.raw, document.recordid.raw, false, t('error_Download_Message_'), compoundClick);

                                                            trackDocData(document?.id?.raw, document?.title?.raw, document?.recordid?.raw, compoundClick);
                                                        }}
                                                        download={document.id.raw}
                                                        target="_blank"
                                                    >
                                                        <FaFileDownload className='indigo' style={{ cursor: 'pointer', paddingLeft: "5px" }} />
                                                    </a>
                                                </>
                                            }
                                        </span>
                                    </p>
                                </div>

                            </div>
                            {/* } */}
                            {siblingDocs && siblingDocs.map((sibling) => {
                                const sibdocSize = getFilterValueDisplay(Math.round(sibling.datasize.raw / 1024));
                                const sibdocType = getFilterValueDisplay((sibling.filetype.raw).toUpperCase());
                                const sibdate = new Date(sibling.modifydate.raw).toLocaleString('en', { dateStyle: 'medium' });
                                const sibexpiryDate = sibling.expirationdate && sibling?.expirationdate?.raw.length > 0 ? new Date(sibling?.expirationdate?.raw).toLocaleString('en', { dateStyle: 'medium' }) : 'N/A'
                                return (
                                    <ul className="bare-list display-flex flex-wrap" >
                                        <li className="bare-list__item margin-0 margin-right-minimal-2"><FaCalendarAlt /><span dltooltip="" data-content="Published">{sibdate}</span></li>
                                        <li className="bare-list__item margin-0 margin-right-minimal-2 ng-star-inserted"><MdLanguage /><span>{getFilterValueDisplay(t(sibling.language.raw))}</span></li>
                                        <li className="bare-list__item margin-0 margin-right-minimal-2">
                                            <span>
                                                <a
                                                    href='#'
                                                    onClick={(evt) => {
                                                        setLoading(!load);

                                                        documentClick(sibling.id.raw, sibling.filetype.raw, sibling.name.raw, evt, isEmployed, Cookies.get('at'), setLoading, false, t('error_Download_Message_'), documentsClick);

                                                        trackDocData(sibling?.id?.raw, sibling?.title?.raw, document?.recordid?.raw, documentsClick);
                                                    }}
                                                    download={sibling.id.raw}
                                                    target="_blank"
                                                >
                                                    <FaFileDownload
                                                        className='indigo'
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    {sibdocType} {sibdocSize} KB
                                                </a>
                                            </span>
                                        </li>
                                    </ul>
                                )
                            })

                            }
                            <div className="margin-top-1 overflow-x-auto">
                                {document.name && document.name.raw.length > 0 &&
                                    <div style={{ display: 'grid', gridTemplateColumns: '2.5fr 4fr' }}>

                                        <div>{t('Name')}: </div>
                                        {
                                            document.parenttype && document.parenttype.raw !== 'CompoundDocument' &&
                                            <div>
                                                {getFilterValueDisplay(document.name.raw)}
                                            </div>
                                        }

                                        {
                                            document.parenttype && document.parenttype.raw === 'CompoundDocument' &&
                                            <div>
                                                {getFilterValueDisplay(document.recordid.raw)}
                                            </div>
                                        }

                                    </div>
                                }

                                <hr></hr>

                                {
                                    siblings != null && siblings.length >= 2 &&
                                    <div>
                                        {/* This is download all documents section */}
                                        <div>
                                            {t('Compound Documents')}
                                            <a
                                                href="#"
                                                onClick={(evt) => {
                                                    setLoading(!load);
                                                    downloadCompoundDocuments(evt, siblings, setLoading)
                                                }}
                                                target="_blank"
                                            >
                                                {t('Download All')}

                                                <FaFileDownload
                                                    className='indigo'
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <FaFileDownload
                                                    className='indigo'
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <FaFileDownload
                                                    className='indigo'
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </a>

                                        </div>

                                        {/* After clicking Details, other docs are here */}
                                        <div style={{ display: 'grid', gridTemplateColumns: '3fr 3fr 3fr' }}>
                                            {
                                                siblings.length >= 2 && siblings.map((sibling) => {
                                                    const sibdocSize = sibling.data.datasize != null ? getFilterValueDisplay(Math.round(sibling.data.datasize.raw / 1024)) : 0;
                                                    const sibdocType = sibling.data.filetype != null ? getFilterValueDisplay((sibling.data.filetype.raw).toUpperCase()) : 'N/A';
                                                    const sibdocName = sibling.data.name != null ? getFilterValueDisplay((sibling.data.name.raw)) : 'NoName'
                                                    return (
                                                        < ul className="bare-list display-flex flex-wrap" style={{ border: '1px solid', borderRadius: '9px', margin: '5px' }}>
                                                            <li bare-list__item margin-0 margin-right-minimal-2 style={{ margin: '5x' }}>

                                                                {/* After clicking Details, This is the document name part */}
                                                                <span style={{ paddingLeft: "5px" }}>
                                                                    {
                                                                        sibling?.data?.filetype?.raw?.toLowerCase() === "pdf"
                                                                            ?
                                                                            <a
                                                                                href='#'
                                                                                onClick={(evt) => {
                                                                                    setLoading(!load);

                                                                                    documentView(sibling.data.id.raw, sibling.data.filetype.raw, sibling.data.name.raw, evt, isEmployed, Cookies.get('at'), setLoading, false, t('error_Download_Message_'), documentsClick);

                                                                                    if (sibling?.data?.parenttype?.raw?.toString().toLowerCase() === "compounddocument") {
                                                                                        trackDocData(sibling?.data?.id?.raw, sibling?.data?.title?.raw, document?.recordid?.raw, compoundClick);
                                                                                    }
                                                                                    else {
                                                                                        trackDocData(sibling?.data?.id?.raw, sibling?.data?.title?.raw, document?.recordid?.raw, documentsClick);
                                                                                    }
                                                                                }}
                                                                                download={sibling.data.id.raw}
                                                                                target="_blank"
                                                                            >
                                                                                {sibdocName} {sibdocSize} KB
                                                                            </a>
                                                                            :
                                                                            <a href='#'
                                                                                onClick={(evt) => {
                                                                                    setLoading(!load);

                                                                                    documentClick(sibling.data.id.raw, sibling.data.filetype.raw, sibling.data.name.raw, evt, isEmployed, Cookies.get('at'), setLoading, false, t('error_Download_Message_'), documentsClick);

                                                                                    if (sibling?.data?.parenttype?.raw?.toString().toLowerCase() === "compounddocument") {
                                                                                        trackDocData(sibling?.data?.id?.raw, sibling?.data?.title?.raw, document?.recordid?.raw, compoundClick);
                                                                                    }
                                                                                    else {
                                                                                        trackDocData(sibling?.data?.id?.raw, sibling?.data?.title?.raw, document?.recordid?.raw, documentsClick);
                                                                                    }
                                                                                }} download={sibling.data.id.raw} target="_blank">
                                                                                {sibdocName} {sibdocSize} KB
                                                                            </a>
                                                                    }
                                                                </span>

                                                                {/* This is the download ICON */}
                                                                <span style={{ paddingLeft: "15px" }}>
                                                                    <a href='#'
                                                                        onClick={(evt) => {
                                                                            setLoading(!load);

                                                                            documentClick(sibling.data.id.raw, sibling.data.filetype.raw, sibling.data.name.raw, evt, isEmployed, Cookies.get('at'), setLoading, false, t('error_Download_Message_'), documentsClick);

                                                                            if (sibling?.data?.parenttype?.raw?.toString().toLowerCase() === "compounddocument") {
                                                                                trackDocData(sibling?.data?.id?.raw, sibling?.data?.title?.raw, document?.recordid?.raw, compoundClick);
                                                                            }
                                                                            else {
                                                                                trackDocData(sibling?.data?.id?.raw, sibling?.data?.title?.raw, document?.recordid?.raw, documentsClick);
                                                                            }
                                                                        }}
                                                                        download={sibling.data.id.raw}
                                                                        target="_blank"
                                                                    >
                                                                        {/* <BiGlasses /> */}
                                                                        <FaFileDownload className='indigo' style={{ cursor: 'pointer' }} />
                                                                    </a>
                                                                </span>

                                                            </li>
                                                        </ul>
                                                    )
                                                })
                                            }
                                        </div>

                                        <hr></hr>
                                    </div>

                                }

                                <table role="grid" className="docuemnt__meta-table">
                                    <tbody>

                                        {document.legacy_identifier && document.legacy_identifier.raw.length > 0 &&
                                            <tr><td>{t('Legacy_Identifier')}: </td><td>{getFilterValueDisplay(document.legacy_identifier.raw)}</td></tr>}
                                        {isEmployed && document.tsc_priority && document.tsc_priority.raw.length > 0 &&
                                            <tr><td>{t('TSC_ Priority')}: </td><td>{getFilterValueDisplay(document.tsc_priority.raw)}</td></tr>}
                                        {isEmployed && document.fe_priority && document.fe_priority.raw.length > 0 &&
                                            <tr><td>{t('FE_ Priority')}: </td><td>{getFilterValueDisplay(document.fe_priority.raw)}</td></tr>}
                                        {isEmployed && document.solution && document.solution.raw.length > 0 &&
                                            <tr><td>{t('Solution')}: </td><td>{getFilterValueDisplay(document.solution.raw)}</td></tr>}
                                        {document.lot && document.lot.raw.length > 0 &&
                                            <tr><td>{t('Lot')}: </td><td>{getFilterValueDisplay(document.lot.raw)}</td></tr>}
                                        {isEmployed && document.potentialhealth && document.potentialhealth.raw.length > 0 &&
                                            <tr><td>{t('Potential_Health')}: </td><td>{getFilterValueDisplay(document.potentialhealth.raw)}</td></tr>}
                                        {document.business_line && document.business_line.raw.length > 0 &&
                                            <tr><td>{t('Business_Line')}: </td><td>{getFilterValueDisplay(document.business_line.raw)}</td></tr>}
                                        {document.document_classification && document.document_classification.raw.length > 0 &&
                                            <tr><td>{t('Doc_Categories')}: </td><td>{getFilterValueDisplay(altDispValues[document.document_classification.raw] !== undefined ? altDispValues[document.document_classification.raw] : t(document.document_classification.raw))}</td></tr>}
                                        {document.document_classification_subcategory && document.document_classification_subcategory.raw.length > 0 &&
                                            <tr><td>{t('Doc_Sub_Categories')}: </td><td>{getFilterValueDisplay(altDispValues[document.document_classification_subcategory.raw] !== undefined ? altDispValues[document.document_classification_subcategory.raw] : t(document.document_classification_subcategory.raw))}</td></tr>}
                                        {document.description && document.description.raw.length > 0 &&
                                            <tr><td>{t('Description')}: </td><td>{getFilterValueDisplay(document.description.raw)}</td></tr>}
                                        {document.productslist && document.productslist.raw !== null &&
                                            <tr><td>{t('Products')}</td>
                                                <td>
                                                    {document.productslist.raw.map(products => {
                                                        let product = null;
                                                        try {
                                                            product = JSON.parse(products)
                                                        }
                                                        catch (e) {
                                                            console.error('exception occurred ', e)
                                                        }
                                                        return (
                                                            <table key={`product-${products}`}><tbody>
                                                                {product.product && product.product.length > 0 &&
                                                                    <tr><td>{t('Product')}: </td><td>{altDispValues[product.product] ? altDispValues[product.product] : t(product.product)}</td></tr>}
                                                                {product.reagenttype && product.reagenttype.length > 0 &&
                                                                    <tr><td>{t('Reagent_Type')}: </td><td>{altDispValues[product.reagenttype] ? altDispValues[product.reagenttype] : t(product.reagenttype)}</td></tr>}
                                                                {product.abbreviation && product.abbreviation.length > 0 &&
                                                                    <tr><td>{t('Abbreviation')}: </td><td>{altDispValues[product.abbreviation] ? altDispValues[product.abbreviation] : t(product.abbreviation)}</td></tr>}
                                                                {product.phsassay && product.phsassay.length > 0 &&
                                                                    <tr><td>{t('Phsassay')}: </td><td>{altDispValues[product.phsassay] ? altDispValues[product.phsassay] : t(product.phsassay)}</td></tr>}
                                                                {product.catalogno && product.catalogno.length > 0 &&
                                                                    <tr style={{ border: "0px" }}><td>{t('Catalog_No')}: </td><td>{altDispValues[product.catalogno] ? altDispValues[product.catalogno] : product.catalogno}</td></tr>}
                                                            </tbody></table>)
                                                    })}
                                                </td></tr>}
                                        {document.revision && document.revision.raw.length > 0 &&
                                            <tr><td>{t('Revision')}: </td><td>{getFilterValueDisplay(document.revision.raw)}</td></tr>}

                                        {!isEmployed && document.equipmentlist && document.equipmentlist.raw !== null && document.equipmentlist.raw.length > 0 &&
                                            <tr>
                                                <td key={document.id.raw}>{t('Equipment_List')}: </td>
                                                <td>
                                                    {
                                                        document.equipmentlist.raw.map(eq => {
                                                            return (
                                                                <>
                                                                    {getFilterValueDisplay(altDispValues[eq] !== undefined ? altDispValues[eq] : eq)}
                                                                    <br />
                                                                </>
                                                            )
                                                        })}
                                                </td></tr>}
                                        {isEmployed && document.equipmentlist && document.equipmentlist.raw && document.equipsubmodulelist && document.equipsubmodulelist.raw !== null && document.equipsubmodulelist.raw.length > 0 &&
                                            <tr><td>{t('Equipment_List')}: </td>
                                                <td>
                                                    <table>
                                                        {
                                                            document.equipsubmodulelist.raw.map((eq, index) => {
                                                                let equipment = null
                                                                try {
                                                                    equipment = JSON.parse(eq)
                                                                } catch (e) { console.error("error occurred equipment", e) }
                                                                return (
                                                                    <>
                                                                        {index === 0 && equipment.equipment && <thead><tr><td>{t('Equipment')}</td><td>{t('Submodule')}</td></tr></thead>}
                                                                        <tr className={`${index === document.equipsubmodulelist.raw.length - 1 ? 'hideBorder' : 'showBorder'}`}>{equipment.equipment && <td> {getFilterValueDisplay(altDispValues[equipment.equipment] !== undefined ? altDispValues[equipment.equipment] : equipment.equipment)}</td>}
                                                                            {equipment.submodule && <td>{getFilterValueDisplay(altDispValues[equipment.submodule] !== undefined ? altDispValues[equipment.submodule] : equipment.submodule)}</td>}
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}
                                                    </table>
                                                </td></tr>}
                                        {document.referencelist && document.referencelist.raw &&
                                            <tr><td>{t('References')}: </td>
                                                <td key={document.id.raw}><table><tbody>
                                                    {document.referencelist.raw.map((references, index) => {
                                                        let reference = null
                                                        try {
                                                            reference = JSON.parse(references)
                                                        } catch (e) { }
                                                        const referenceFileType = reference.document_name.split('.').pop().toLowerCase();
                                                        return (
                                                            <table><tbody>
                                                                {reference.reference_id && reference.reference_id.length > 1 && mimeTypesList.includes(referenceFileType) &&
                                                                    <>
                                                                        <tr><td key={index}>{t('Reference_Id')}: </td><td>{reference.reference_id}</td></tr>
                                                                        <tr style={{ border: "0px" }}>
                                                                            <td>
                                                                                {t('Document_name')}:
                                                                            </td>
                                                                            <td>
                                                                                <a
                                                                                    href='#'
                                                                                    className='indigo'
                                                                                    onClick={(evt) => {
                                                                                        setLoading(!load);

                                                                                        documentClick(reference.reference_id, referenceFileType, reference.document_name, evt, isEmployed, Cookies.get("at"), setLoading, false, t('error_Download_Message_'), documentsClick);

                                                                                        trackDocData(reference.reference_id, document?.title?.raw, document?.recordid?.raw, documentsClick);
                                                                                    }}
                                                                                    download={reference.reference_id}
                                                                                    target="_blank"
                                                                                >
                                                                                    {reference.reference_title}
                                                                                    <FaFileDownload
                                                                                        className='indigo'
                                                                                        style={{ cursor: 'pointer' }}
                                                                                    />
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                }
                                                            </tbody></table>)
                                                    })}
                                                </tbody></table></td></tr>}
                                        {document.regioncountrylist && document.regioncountrylist.raw.length > 0 &&
                                            <tr><td>{t('Countries')}: </td><td>{getFilterValueDisplay(document.regioncountrylist.raw)}</td></tr>}
                                        {document.audience && document.audience.raw.length > 0 &&
                                            <tr><td>{t('Audience')}: </td><td>{getFilterValueDisplay(document.audience.raw)}</td></tr>}
                                        {document.filetype && document.filetype.raw.length > 0 &&
                                            <tr><td>{t('File_Format')}: </td><td>{docType}</td></tr>}
                                        {document?.keywords?.raw?.length > 0 && document?.equipkeywords?.raw?.length > 0 ?
                                            <tr><td>{t('Keywords')}: </td>
                                                <td className='document_keywords'>{getFilterValueDisplay(document.keywords.raw)}, {getFilterValueDisplay(document.equipkeywords.raw)}</td>
                                            </tr> :
                                            null
                                        }
                                        {document?.keywords?.raw?.length > 0 && !document?.equipkeywords?.raw?.length > 0 ?
                                            <tr><td>{t('Keywords')}: </td>
                                                <td className='document_keywords'>{getFilterValueDisplay(document.keywords.raw)}</td>
                                            </tr> :
                                            null
                                        }
                                        {!document?.keywords?.raw?.length > 0 && document?.equipkeywords?.raw?.length > 0 ?
                                            <tr><td>{t('Keywords')}: </td>
                                                <td className='document_keywords'> {getFilterValueDisplay(document.equipkeywords.raw)}</td>
                                            </tr> :
                                            null
                                        }

                                        {isEmployed && document.usage_type && document.usage_type.raw && document.usage_type.raw[0].length > 0 &&
                                            <tr><td>{t('Usage_Type')}: </td><td>{getFilterValueDisplay(document.usage_type.raw)}</td></tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </article >

    );
};




export { Documents, /*Document,*/ DetailsModal, DocumentResult };