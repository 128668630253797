import { getConfig } from "../config/config-helper";
import Cookies from 'js-cookie';



export const trackDocData = (docId, title, recordId, clickType) => {
    if (docId && title && clickType) {
        const { endpointBaseClick, search_key } = getConfig();


        const urlParams = new URLSearchParams(window.location.search);

        const query = urlParams
            .get("search-term")
            ?
            Cookies.get("search-term")
                ?
                Cookies.get("search-term") : ''
            :
            '' || urlParams.get("docId")
                ?
                Cookies.get("docId")
                    ?
                    Cookies.get("docId") : ''
                :
                '' || (urlParams.get("q")
                    ?
                    urlParams.get("q") : '');

        // -------------------------------------------- //



        const headers = {
            'Accept': '*/*',
            'Accept-Encoding': 'gzip, deflate, br',
            'Connection': 'keep-alive',
            'Authorization': `Bearer ${search_key} `,
            'Content-Type': 'application/json'
        };

        // -------------------------------------------- //

        // Payload with record-id and title

        // let payloadTemp = {
        //     "query": query,
        //     "document_id": docId,
        //     "title": title,
        //     "tags": [clickType]
        // };

        // if (recordId) {
        //     payloadTemp = {
        //         "query": query,
        //         "document_id": docId,
        //         "title": title,
        //         "record_id": recordId,
        //         "tags": [clickType]
        //     };
        // }

        // ------------------------------------------ //

        


        // Payload without document-id and title 
        // Note: As document_id is reqd parameter, so sending recordId with document_id tag
        let payloadTemp = {
            "query": query,
            "document_id": recordId,
            "tags": [clickType]
        };

        const payload = JSON.stringify(payloadTemp);



        // API Call
        fetch(endpointBaseClick, {
            method: 'post',
            headers: headers,
            body: payload
        })
            .catch((err) => {
                console.log("Document Track API Error:", err);
            });
    }
}